import * as types from "./types.js";


export const setProgressLoadingByClassId = (payload) => ({
    type: types.SET_TEACHER_STUDENTS_PROGRESS_LOADING_BY_CLASS_ID,
    payload,
});

export const setProgressByClassId = (payload) => ({
    type: types.SET_TEACHER_STUDENTS_PROGRESS_BY_CLASS_ID,
    payload,
});

export const setProgressRenewingByClassId = (payload) => ({
    type: types.SET_TEACHER_STUDENTS_PROGRESS_RENEWING_BY_CLASS_ID,
    payload,
});

export const setStudentProgressErrorByClassId = (payload) => ({
    type: types.SET_TEACHER_STUDENTS_PROGRESS_ERROR_BY_CLASS_ID,
    payload,
});

export const setStudentProgressFrequency = (payload) => ({
    type: types.SET_TEACHER_STUDENTS_PROGRESS_FREQUENCY_BY_CLASS_ID,
    payload,
});

export const setStudentProgressAutoAdjust = (payload) => ({
    type: types.SET_TEACHER_STUDENTS_PROGRESS_AUTO_ADJUST_BY_CLASS_ID,
    payload,
});

export const setStudentProgressLoading = (payload) => ({
    type: types.SET_TEACHER_STUDENTS_PROGRESS_STUDENT_IS_LOADING,
    payload,
});

export const setStudentProgressRecommendationAccepted = (payload) => ({
    type: types.SET_TEACHER_STUDENTS_PROGRESS_STUDENT_RECOMMENDATION_ACCEPTED,
    payload,
});
