export default (apiURL, methods) => {
    return {
        getScoreReport(params = {}) {
            // NOTE: action=get_score_report
            const url = `${apiURL}/teacher/classes/${params.classId || ""}/reports/scores`;
            const standards = (params.standardIds || []).join(",");

            return methods.get3(url, {
                session_id: params.session || "",
                standards,
                date_from: params.dateFrom || "",
                date_to: params.dateTo || "",
            });
        },

        getProgressReport(params = {}) {
            // NOTE: action=teacher_student_intervention_progress_report
            const url = `${apiURL}/teacher/classes/${params.classId || ""}/reports/progress`;

            return methods.get3(url, {
                session_id: params.session || "",
            });
        },

        generateProgressReport(params = {}) {
            // NOTE: action=teacher_generate_new_student_intervention_progress_report
            const url = `${apiURL}/teacher/classes/${params.classId || ""}/reports/progress/new`;

            return methods.get3(url, {
                session_id: params.session || "",
            });
        },

        setProgressReportFrequency(params = {}) {
            // NOTE: action=student_intervention_progress_report_update_frequency
            const url = `${apiURL}/teacher/classes/${params.classId || ""}/reports/progress/frequency`;

            return methods.post(url, {
                session_id: params.session || "",
                class_id: params.classId || "",
                frequency: params.frequency || "",
            });
        },

        setProgressReportAutoAdjust(params = {}) {
            // NOTE: action=student_intervention_progress_report_update_auto_adjust
            const url = `${apiURL}/teacher/classes/${params.classId || ""}/reports/progress/auto-adjust`;

            return methods.post(url, {
                session_id: params.session || "",
                class_id: params.classId || "",
                auto_adjust: params.autoAdjust ? 1 : 0,
            });
        },

        setProgressReportViewed(params = {}) {
            // NOTE: action=student_intervention_set_viewed
            const url = `${apiURL}/teacher/classes/${params.classId || ""}/reports/progress/set-viewed`;

            return methods.get3(url, {
                session_id: params.session || "",
            });
        },
    };
};
